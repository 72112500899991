@media (min-width: 601px) and (max-width:1024px) {

    *{
        font-size: 2vw;
    }


    
    #header{
        position:relative;
        justify-content: center;
        align-items: center;
      }
      #footer{

        height: 15vh;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      #nav{
        width: 100vw;
        display: flex;
        justify-content: space-around;
        align-items: center;
      }
      .content{
        border-bottom: 1px solid white;
        height: 60vh;
      }
      .paraHome{
        text-align: justify!important;
      }
      #text{
        padding: 2% 5% 0 5%;
      }
      #text p{
        text-align: center;
      }


      #actualidadImages{
        
        height: 60vh;
        background-color: white;
        
      }
      #actualidadImages .proyecto img{
        height: auto;
       width: auto!important;
       max-width: 20vw;
       max-height: 30vh;
      }
      #actualidadCarousel{
          padding-top: 10vh;
      }
      #actualidadImages .rec-swipable{
        align-items: flex-end!important;
        display: fex;
      }

      #projectImages{
        display: flex;
          height: 35vh;
      }
      #imageBox{
        width: 90vw;
        display: flex;
        justify-content: center;
        align-items:flex-end;
      }
      .projectImage{
        height: auto;
       width: auto!important;
       max-width: 90vw;
       max-height: 35vh;
      }
      #imageButton{
        width: 5vw;
      }
      #imageButton button{
        
        font-size: 25px;
        width: 100%;
        height: 100%;
        background-color: transparent;
        border:transparent
      }
      
      #projectImages .carousel-row{
        display: flex;
          align-items: flex-end;
          justify-content: center;
          height: 40vh;
      }
      .carousel-row img{
       height: auto;
       width: auto!important;
       max-width: 100vw;
       max-height: 40vh;
      }
      #projectText{
          height: 20vh;
          padding-top:2%!important;
          text-align: center;
      }
      #carouselMenu{
          height: 10vh;
          padding-top:3%
      }

      .imageMenu{
        height: auto;
        width: auto!important;
        max-width: 8vw;
        max-height: 8vh;
      }
      #carouselMenu .rec-carousel-item{
        display: flex!important;
        align-items: flex-end;
        justify-content: center;
      }
      .proyecto p{
          text-align: center;
      }
      .logo{
          width: 10vw;
          fill:#666
      }


      
      .columnContact{
        width: 15vw ;
        display: inline-block;
      }
      
      #contactText{
        width: 100vw;
        height: 60vh;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;  
      }
      #contactText #contact{
        padding:5%
      }
      .columnContact{
        width: 33%; 
      }
      .contactTitulo, span{
        font-size: 5vw;
      }
      .logo{
        fill:#666;
        width:3vw;
        margin-left: 5%;
        margin-right: 5%; 
      }
}