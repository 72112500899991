@media (min-width: 1025px) {
  *{
    font-size: .7vw;
  }
  #carousel p{
    font-size: .6vw;
  }
    #header h1{
      padding-left:5vw;
      padding-bottom: 1%;

    }
    .headerLink{
      font-size: .8vw;
    }
  #header{
    align-items: flex-end;
  }
  .content{
    display:flex;
    height: 50vh;
    border-bottom: 1px solid white;
  }
  
  #footer a{
    padding-right: 6%;
  }

  #projectText,#actualidadText,#projectText,#homeText{
    width: 30vw;
  }
  #homeImages,#projectImages{
    width: 66vw;
    padding-left:4vw;
    display: flex;
  }

  /*------------------------------------------------HOME------------------------------------------------ */

  
  
  #homeText #text{
    padding: 5% 0 0 2vw;
    
  }
  #homeText{
    align-items: flex-start;
    display: flex;
  }
  #homeText p{
    width: 23vw;
    text-align: justify;
  }

  /*------------------------------------------------FIN HOME------------------------------------------------*/

  /*------------------------------------------------PROYECTOS------------------------------------------------*/
  
  
  #projectText p{
    padding:5% 0 0% 10%;
    color:#666
  }
  #projectText{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .projectTextPhotographer p{
    padding-bottom: 5%!important;
  }
  .projectT{
    padding-top:2%!important
  }
  #carousel{
    height: 20vh;
    display: flex;
    align-items: center;
  }
  #carouselMenu{
    width: 96%;
    margin:auto
  }
  #nav{
    width: 90%;
    margin:auto
  }
  #carouselMenu p{
    text-align: center;
    padding-top: 1vw;
  }
  .imageMenu{
    height: auto;
    width: auto!important;
    max-width: 6.5vw;
    max-height: 6.5vh;
    transition: transform .2s;
  }
  #carouselMenu .rec-carousel-item{
    display: flex!important;
    align-items: flex-end;
    justify-content: center;
  }
  #carouselMenu .rec-carousel-item:hover p{
    color:white
  }
  #carousel .imageMenu:hover{
    transform: scale(3);
     /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }

  .selected {
    color:white
  }
  
#projectImages .carousel-row{
  display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    height: 50vh;
}
.carousel-row img{
 height: auto;
 width: auto!important;
 max-width: 70vw;
 max-height: 50vh;
}

#imageButton{
  width: 1vw;
}
 .right{
  width: 2vw;
}
#imageButton .button{
  
  font-size: 25px;
  width: 100%;
  height: 100%;
  background-color: transparent;
  border:transparent
}
#imageButton button:hover{
  color:white
}
.projectImage{
  height: auto;
 width: auto!important;
 max-width: 100%!important;
 max-height: 50vh;
 
}

#imageBox{
  width: 68vw;
  display: flex;
  justify-content: flex-end;
  align-items:flex-end;
  border-right: 1px solid white;
}

  /*------------------------------------------------FIN PROYECTOS------------------------------------------------*/

  /*------------------------------------------------ACTUALIDAD------------------------------------------------*/
  #actualidadImages{
    width: 100vw;
  }
  #actualidadCarousel{
    width: 65vw;
    margin-left: 5vw;
  }
  #actualidadImages{
    background-color: white;
    display: flex;
    align-items: center;
    
  }
  
  #actualidadImages .proyecto{
    text-align: center;
    font-size: 0.7em;

}
#actualidadImages .proyecto img{
  height: auto;
 width: auto!important;
 max-width: 10vw;
 max-height: 30vh;
}

#actualidadImages .rec-swipable{
  align-items: flex-end!important;
  display: fex;
}
  /*------------------------------------------------FIN ACTUALIDAD------------------------------------------------*/

  /*------------------------------------------------CONTACTO------------------------------------------------*/
  #contact{
    text-align: center; 
    margin:auto;
    
  }
  .columnContact{
    width: 15vw ;
    display: inline-block;
  }
  
  #contactText{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;  
  }
  .columnContact{
    width: 33%; 
  }
  .contactTitulo{
    padding-bottom: 5%;
  }
  .contactTitulo, span{
    font-size: 2vw;
  }
  .logo{
    fill:#666;
    width:1.6vw;
    margin-left: 5%;
    margin-right: 5%; 
  }
    /*------------------------------------------------FIN CONTACTO------------------------------------------------*/
  }

 


  

  
  






