
*{
    color:#666;
    padding: 0px;
    margin:0px;  
    outline: none;
    
  }
  .linkMenu{
    text-align: center;
  }
  a{
    text-decoration: none;
  }
  #nav{
    display: flex;
    width: 50vw;
  }
  .headerLink{
    color:white;
  }
  #header{
    height: 10vh;
    display: flex;
    border-bottom: 1px solid white;
  }
  .page{
    height: 70vh;
  }
  .selected{
    color:white!important
  }

  
  .rec-arrow{
    background-color: transparent!important;
    box-shadow: 0 0 0 0px!important;
  }
  .rec-arrow:disabled{
    color:transparent!important;
    cursor: context-menu!important
    
  }
  .rec-arrow:disabled:hover{
   background-color: transparent!important;
   cursor: context-menu!important
  }
  #actualidadCarousel .sc-bdvvaa:hover {
    color:black!important
  }
  #actualidadCarousel .sc-bdvvaa {
    color:#666!important
  }

  #projectImages .carousel-prev::before{
    background-image: url(../images/arrowLeft.svg)!important;
  }
  #projectImages .carousel-prev:hover::before{
    background-image: url(../images/arrowLeftH.svg)!important;
  }
  #projectImages .carousel-next::before{
    background-image: url(../images/arrowRight.svg)!important;
  }
  #projectImages .carousel-next:hover::before{
    background-image: url(../images/arrowRightH.svg)!important;
  }
 

  .change{
    transition:all 1s;
    opacity: .5;
    
    
  }
  .normal{
    opacity: 1;
   /* filter: blur(10px); */
    transition:all 1s;
  }
  .carouselHome button{
    display: none;
  }
  