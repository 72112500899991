@media (max-width: 600px) {
  *{
    font-size: 4vw;
    
  }
  #header{
    height: 5vh;
    position:relative;
    justify-content: center;
    align-items: center;
  }
  .page{
    height:70vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .content{
    height: 100%;
    width: 100vw!important;
  }
  
  #footer{
    padding:0 5vw;
    height: 15vh;
    border-top: 1px solid white; 
    display: flex;
    justify-content: center;
    align-items: center;
  }
  #nav{
    width: 100vw;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
 
  
  
    

/*------------------------------------------------HOME------------------------------------------------*/
  .paraHome{
    text-align: justify!important;
  }

  #homeImages{
    height: 25vh;
  }
  #homeImages img{
    height: auto;
    width: auto!important;
    max-height: 25vh!important;
  }
  #homeImages .carousel-row{
    display: flex;
    justify-content: center;
  }
  #homeText{
    height: 45vh;
  }
  #text{
    padding: 0 5% 0 5%;
  }
  #text p{
    font-size: 3vw;
    text-align: center;
  }
  .imageMenu{
    height: 20vh;
}
  
/*------------------------------------------------FIN HOME------------------------------------------------*/

/*------------------------------------------------PROYECTOS------------------------------------------------*/
  
  
  
  #projectText {
    text-align: center;
  }
  img{
    max-width: 100vw;
  }
  .projectsPhone{
    text-align: center;
  }
  #projectImages{
    width: 100vw;
    display: flex;
    height: 30vh;

  }
  #imageBox{
    width: 90vw;
    display: flex;
    justify-content: center;
    align-items:flex-start;
  }
  .projectImage{
    height: auto;
   width: auto!important;
   max-width: 90vw;
   max-height: 30vh;
  }
  #imageButton{
    width: 5vw;
  }
  #imageButton button{
    font-size: 25px;
    width: 100%;
    height: 100%;
    background-color: transparent;
    border:transparent
  }
/*------------------------------------------------FIN PROYECTOS------------------------------------------------*/
 .actualidadPhone , .projectsPhone{
    max-height: 70vh;
    overflow: scroll;
  white-space: nowrap;
  
  }
  .actualidadPhone .proyecto{
    margin:5%
  }
  .imageMenu{
    width: 80vw;
    height: auto!important;
  }
  .projectsPhone img{
    height: auto;
    width: auto!important;
    max-width: 70vw;
    max-height: 50vh;
  }
  .projectPhone{
    padding:5% 0
  }
  .projectPhone p{
    padding:5% 0
  }
/*------------------------------------------------ACTUALIDAD------------------------------------------------*/
  .actualidadPhone{
    text-align: center;
    background-color: white;
  }
  .actualidadPhone img{
    height: auto;
    width: auto!important;
    max-width: 80vw;
    max-height: 50vh;
  }
  #actualidadCarousel{
    width: 100%;
    text-align: center;
  }
/*------------------------------------------------FIN ACTUALIDAD------------------------------------------------*/

/*------------------------------------------------CONTACTO------------------------------------------------*/
  #contactText{
    height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .contactTitulo,span{
    font-size: 4vw;
    text-align: center;
    margin-bottom: 5vw;
  }
  
#contact{
  display: flex   ;
  flex-direction: column
}
#contact .logos{
  order:3
}
.columnContact{
  margin:2% 0
}
  .columnContact p, .columnContact{
    text-align: center!important;
  }
  .logo{
    fill:#666;
    width:40px;
    margin:2%
  }
/*------------------------------------------------FIN CONTACTO------------------------------------------------*/

  /*------------------------------------------------MODAL------------------------------------------------*/
  .modal .rec-arrow{
    background-color:transparent!important; 
  }
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: #000;
    opacity: .5;
  }

  .modal-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
  }

  .modal {
    z-index: 100;
    background:   rgba(0, 0, 0);
    position: absolute;
    height: 100vh;
    width: 100vw;
  }

  .modal-header {
    display: flex!important;
    justify-content: flex-end!important;
    height: 10vh;
  }
  .modal-body{
    display: flex;
    align-items: center;
    flex-direction: column;
    max-height: 80vh;
    overflow: scroll;
  white-space: nowrap;
  text-align: center;
  }
  .modal-body p{
    text-align: left;
    width: 90vw;
    white-space: pre-wrap
  }
  .modal-body img{
    width: 90%;
    margin:5% 0
  }
  
  .modal-body .rec-carousel-wrapper{
    width: 100vw;
  }
  .modal-body .rec-item-wrapper{
    align-items: flex-end;
    height: 100%;
  }
  .modal-body  p{
    padding-top:5%
  }
  /* .modal-body img{
    height: auto;
    width: auto!important;
    max-width: 65vw;
    max-height: 30vh;
  } */
  .modal-close-button {
    font-size: .9rem;
    font-weight: 700;
    border: none;
    border-radius: 3px;
    padding: .3rem 1rem;
    margin-left: .5rem;
    background-color: transparent;
    border:3px solid #666;
    
    
  }
  .modal-close-button span{
    font-size: 6vw;
  }
  
  

/*------------------------------------------------FIN MODAL------------------------------------------------*/
  }

  @media (max-width:850px) and (orientation:landscape){

    .page {
      height: 100vh;
    }
    .content ,#actualidadImages{
      height: 80vh;
    }
    .contentHome{
      height: 100vh;
    }
  }